import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

export default function AboutUs() {
  return (
    <Layout>
      <Seo
        title="About Us"
        description="CAST's implementation of rigorous yet realistic standards; minimize risk, reduce environmental impact and provide safe working solutions."
        pathname="/about-us"
      />
      <section className="hero hero--about-us px-4 bg-brand-blue flex items-center relative">
        <StaticImage
          className="absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full object-cover"
          src="../images/hero-about-us-v2.jpg"
          alt="About Us"
          placeholder="blurred"
        />
        <div className="container mx-auto relative z-10">
          <div className="hero__content text-center">
            <h1 className="text-white pb-4 relative">
              About Us
              <span className="border-b-2 border-brand-orange inline-block h-1 w-24 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
            </h1>
          </div>
        </div>
      </section>
      <section className="about-pillars px-4 py-24 relative">
        <div className="container mx-auto grid md:grid-cols-2 md:gap-4 lg:grid-cols-4">
          <div className="about-pillars__pillar text-center mb-12 md:mb-6">
            <StaticImage
              className="w-20 mb-4 inline-block"
              src="../images/icon-about-us.png"
              alt="About Us"
              placeholder="blurred"
            />
            <div className="pillar__triangle"></div>
            <div className="pillar__content text-left px-4">
              <h3 className="text-brand-blue text-center">About Us</h3>
              <h4 className="text-brand-orange font-semibold text-center mb-4">
                Who are we?
              </h4>
              <p className="mb-4">
                CAST Rope Access is a South African turnkey solution service
                provider to the Renewable, Oil and Gas, Construction and Marine
                sectors.
              </p>
              <p>
                <span className="text-brand-orange font-semibold block">
                  CAST- A Philosophy of Continuous Improvement
                </span>
                CAST’s Implementation of rigorous yet realistic standards;
                minimize risk, reduce environmental impact and provide{" "}
                <span className="text-brand-orange font-semibold">SAFE</span>{" "}
                working solutions.
              </p>
            </div>
          </div>
          <div className="about-pillars__pillar text-center mb-12 md:mb-6">
            <StaticImage
              className="w-20 mb-4 inline-block"
              src="../images/icon-mission.png"
              alt="Mission"
              placeholder="blurred"
            />
            <div className="pillar__triangle"></div>
            <div className="pillar__content text-left px-4">
              <h3 className="text-brand-blue text-center">Mission</h3>
              <h4 className="text-brand-orange font-semibold text-center mb-4">
                Our purpose.
              </h4>
              <p className="mb-4">
                "To lead, through continual improvement in everything we do."
              </p>
            </div>
          </div>
          <div className="about-pillars__pillar text-center mb-12 md:mb-6">
            <StaticImage
              className="w-20 mb-4 inline-block"
              src="../images/icon-vision.png"
              alt="Vision"
              placeholder="blurred"
            />
            <div className="pillar__triangle"></div>
            <div className="pillar__content text-left px-4">
              <h3 className="text-brand-blue text-center">Vision</h3>
              <h4 className="text-brand-orange font-semibold text-center mb-4">
                Where we want to be.
              </h4>
              <p className="mb-4">
                "To exceed the expectations of our customers through providing
                services that are relevant to ever changing global markets."
              </p>
            </div>
          </div>
          <div className="about-pillars__pillar text-center mb-12 md:mb-6">
            <StaticImage
              className="w-20 mb-4 inline-block"
              src="../images/icon-values.png"
              alt="Values"
              placeholder="blurred"
            />
            <div className="pillar__triangle"></div>
            <div className="pillar__content text-left px-4">
              <h3 className="text-brand-blue text-center">Values</h3>
              <h4 className="text-brand-orange font-semibold text-center mb-4">
                What differentiates us?
              </h4>
              <p className="mb-4">
                "We believe in forging relationships with key stakeholders in
                the business cycle.
                <br />
                <br />
                We are committed to the sustainable utilization of the earths
                natural resources; through the progression of the renewables
                energy sector and use of limited fossil fuels."
              </p>
            </div>
          </div>
        </div>
        <StaticImage
          className="core-tech-footer-img absolute bottom-0 right-6 sm:right-16"
          src="../images/core-tech-footer-img.png"
          placeholder="blurred"
        />
      </section>
      <section className="about-why px-4 py-24" id="why-asp">
        <h2 className="text-center mb-12 text-brand-blue">Why Work With Us?</h2>
        <div className="container mx-auto">
          <div className="about-why__item mb-12 md:w-3/4 md:mx-auto relative">
            <div className="text-center sm:absolute sm:-top-2 sm:right-0 sm:w-28 sm:h-28 bg-white sm:z-20">
              <div className="opacity-0 sm:opacity-100 absolute top-0 left-0 border-t border-l border-b border-gray-400 w-12 sm:h-full"></div>
              <StaticImage
                className="inline-block w-32 sm:w-auto mb-2 sm:mb-0"
                src="../images/icon-why-ready-to-work.png"
                alt="Ready to Work"
                placeholder="blurred"
              />
            </div>
            <span className="opacity-0 sm:opacity-100 border-t border-gray-400 block h-1 w-full absolute top-3 left-0"></span>
            <h5 className="text-brand-blue font-semibold mb-4 bg-white inline-block pr-4 relative z-10 tracking-widest">
              Living our MISSION VISSION AND VALUES
            </h5>
            <ul className="text-brand-orange list-disc pl-6 mb-6 sm:w-3/4">
              <li>
                <span className="text-brand-darkgray">
                  In everything we do we believe in CHALLENGING THE STATUS-QUO,
                  we believe in THINKING DIFFERENTLY, we believe in CREATING
                  OPPORTUNITY, we are EASY TO DEAL WITH, user friendly and take
                  immense pleasure in offering INTENTIONAL, SAFE, PRODUCTIVE
                  services on and off rope.
                </span>
              </li>
            </ul>
          </div>
          <div className="about-why__item mb-12 md:w-3/4 md:mx-auto relative">
            <div className="text-center sm:absolute sm:-top-2 sm:right-0 sm:w-28 sm:h-28 bg-white sm:z-20">
              <div className="opacity-0 sm:opacity-100 absolute top-0 left-0 border-t border-l border-b border-gray-400 w-12 sm:h-full"></div>
              <StaticImage
                className="inline-block w-32 sm:w-auto mb-2 sm:mb-0"
                src="../images/icon-why-tech-innovation.png"
                alt="Technical Innovation"
                placeholder="blurred"
              />
            </div>
            <span className="opacity-0 sm:opacity-100 border-t border-gray-400 block h-1 w-full absolute top-3 left-0"></span>
            <h5 className="text-brand-blue font-semibold mb-4 bg-white inline-block pr-4 relative z-10 tracking-widest">
              TECHNICAL INNOVATION
            </h5>
            <ul className="text-brand-orange list-disc pl-6 mb-6 sm:w-3/4">
              <li>
                <span className="text-brand-darkgray">
                  CAST design and develop CUSTOMISED ACCESS SOLUTIONS.
                </span>
              </li>
              <li>
                <span className="text-brand-darkgray">
                  We operate with HIGHLY SKILLED, FLEXIBLE and EXPERIENCED
                  teams.
                </span>
              </li>
            </ul>
          </div>
          <div className="about-why__item mb-12 md:w-3/4 md:mx-auto relative">
            <div className="text-center sm:absolute sm:-top-2 sm:right-0 sm:w-28 sm:h-28 bg-white sm:z-20">
              <div className="opacity-0 sm:opacity-100 absolute top-0 left-0 border-t border-l border-b border-gray-400 w-12 sm:h-full"></div>
              <StaticImage
                className="inline-block w-32 sm:w-auto mb-2 sm:mb-0"
                src="../images/icon-why-work-ethic.png"
                alt="Work Ethic"
                placeholder="blurred"
              />
            </div>
            <span className="opacity-0 sm:opacity-100 border-t border-gray-400 block h-1 w-full absolute top-3 left-0"></span>
            <h5 className="text-brand-blue font-semibold mb-4 bg-white inline-block pr-4 relative z-10 tracking-widest">
              WORK ETHIC
            </h5>
            <ul className="text-brand-orange list-disc pl-6 mb-6 sm:w-3/4">
              <li>
                <span className="text-brand-darkgray">
                  CORRECTLY UNDERSTOOD work scopes and related risks are the
                  ﬁrst steps towards SAFE AND EFFICIENT service delivery.
                </span>
              </li>
              <li>
                <span className="text-brand-darkgray">
                  Encourage ACTIVE PROBLEM SOLVING, positivity and a sense of
                  urgency.
                </span>
              </li>
              <li>
                <span className="text-brand-darkgray">
                  We are CONSCIENTIOUS in adhering to our customer's
                  DELIVERABLES.
                </span>
              </li>
            </ul>
          </div>
          <div className="about-why__item mb-12 sm:mb-16 md:w-3/4 md:mx-auto relative">
            <div className="text-center sm:absolute sm:-top-2 sm:right-0 sm:w-28 sm:h-28 bg-white sm:z-20">
              <div className="opacity-0 sm:opacity-100 absolute top-0 left-0 border-t border-l border-b border-gray-400 w-12 sm:h-full"></div>
              <StaticImage
                className="inline-block w-32 sm:w-auto mb-2 sm:mb-0"
                src="../images/icon-why-commercial-advantage.png"
                alt="Commercial Advantage"
                placeholder="blurred"
              />
            </div>
            <span className="opacity-0 sm:opacity-100 border-t border-gray-400 block h-1 w-full absolute top-3 left-0"></span>
            <h5 className="text-brand-blue font-semibold mb-4 bg-white inline-block pr-4 relative z-10 tracking-widest">
              COMMERCIAL ADVANTAGE
            </h5>
            <ul className="text-brand-orange list-disc pl-6 mb-6 sm:w-3/4">
              <li>
                <span className="text-brand-darkgray">
                  CONSISTENT and PROVABLE operation efﬁciency.
                </span>
              </li>
            </ul>
          </div>
          <div className="about-why__item md:w-3/4 md:mx-auto relative">
            <div className="text-center sm:absolute sm:-top-2 sm:right-0 sm:w-28 sm:h-28 bg-white sm:z-20">
              <div className="opacity-0 sm:opacity-100 absolute top-0 left-0 border-t border-l border-b border-gray-400 w-12 sm:h-full"></div>
              <StaticImage
                className="inline-block w-32 sm:w-auto mb-2 sm:mb-0"
                src="../images/icon-why-diverse-skills.png"
                alt="Diversity in Skills"
                placeholder="blurred"
              />
            </div>
            <span className="opacity-0 sm:opacity-100 border-t border-gray-400 block h-1 w-full absolute top-3 left-0"></span>
            <h5 className="text-brand-blue font-semibold mb-4 bg-white inline-block pr-4 relative z-10 tracking-widest">
              DIVERSITY IN SKILLS
            </h5>
            <ul className="text-brand-orange list-disc pl-6 mb-6 sm:w-3/4">
              <li>
                <span className="text-brand-darkgray">
                  Teams contain members who have the ability and skills to
                  fulﬁll MULTIPLE TRADES.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}
